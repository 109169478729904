.grades-dialog,
.generic-grades-dialog {
  z-index: 1300 !important;
}

.generic-grades-dialog .MuiPaper-root {
  width: 600px;
}

@media screen and (max-width: 600px) {
  .generic-grades-dialog .MuiPaper-root {
    width: 100%;
  }
}

.pxn-grade-description {
  white-space: pre-wrap;
}

.grades-dialog {
  .grades-dialog-content {
    .grade-block {
      width: 100%;

      .grade-previous,
      .grade-next {
        top: 60%;
        transform: translateY(-50%);
      }

      .grade-previous {
        left: -40px;
      }

      .grade-next {
        right: -40px;
      }
    }
  }

  @media screen and (min-width: 40em) {
    .MuiDialog-paper {
      min-width: 420px;
    }
  }

  .MuiDialogTitle-root {
    padding: 0 !important;
  }

  .MuiDialogContent-root {
    padding: 24px 48px;
  }

  .MuiIconButton-root.Mui-Disabled {
    color: rgba(0, 0, 0, 0.26);
    background-color: transparent;
  }

  .grade-comparison-mix-block {
    gap: 10px;

    .grade-comparison-mix-list {
      display: grid;
      grid-template-columns: max-content max-content;
      height: max-content;
      grid-gap: 10px;

      .mix-label {
        display: flex;
        align-items: center;
        justify-content: space-between;
      }

      .mix-percent {
        font-weight: bold;
      }
    }
  }

  .grade-comparison-chart {
    min-width: 250px;
    min-height: 180px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;

    .px-chart {
      height: 250px;
      width: 250px;
      position: absolute;
      top: -20px;
    }
  }

  @media screen and (min-width: 40em) {
    .grade-comparison-mix-block {
      gap: 40px;
    }

    .grade-comparison-chart {
      display: block;

      .px-chart {
        top: -40px;
      }
    }
  }
}