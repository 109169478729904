.px-review-box {
  padding: 20px 10px 20px 10px;
  margin-bottom: 20px;
  .px-review-box-header {
    margin-bottom: 5px;
  }
  .px-review-icons {
    margin-bottom: 6px;
  }
}

.px-sticky-header {
  padding-right: 10px;
  margin-bottom: 15px;

  .px-navigation-links {
    padding: 0 10px;
    &.active {
      border-bottom: 2px solid RGB(var(--px_color_link_active));
    }
  }
}

#px-review-import {
  &::after {
    content: '';
    width: 100%;
    height: calc(100vh - 76px - 20px);
  }

  .wrap-word-any {
    word-break: break-all;
  }
  .wrap-word-space {
    word-break: break-word;
  }
}
