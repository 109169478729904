.px-buyoffer-dialog {
  .label-header {
    line-height: 1.2;
  }
  .item-desc-header {
    margin-top: 5px;
  }
  .offerPrice {
    text-align: left;
  }
}

@media screen and (min-width: 640px) {
  .px-buyoffer-dialog {
    .offerPrice {
      text-align: right;
    }
  }
}
