.px-stock {
  position: relative;

  a.gradeScaleUrl {
    color: RGB(var(--px_color_primary));
  }
}

.px-filter-grade-sub-text {
  font-size: 12px;
  letter-spacing: 0.3px;
  line-height: 19px;
  color: RGB(var(--px_color_text_secondary));
  word-break: break-word;
}

.px-grade-margin-top {
  margin-top: 12px !important;
}

.px-grade-margin-vertical {
  margin-top: 12px !important;
  margin-bottom: 12px !important;
}

.px-grade-margin-bottom {
  margin-bottom: 12px !important;
}

.px-filters {
  display: none !important;
}

.px-filters.show {
  display: block !important;
}

.px-stock-list-sm-filter label {
  display: flex;
  // width: 95%;
  padding: 2px 0px;
}

.px-font-size-14px,
.px-font-size-14px .MuiIconButton-label {
  font-size: 14px;
}

.px-stock-list-items {
  .go-to-top {
    position: sticky;
    top: 0;
    display: flex;
    align-items: center;

    &::after {
      content: '';
      width: 100%;
      height: 30px;
      position: absolute;
      top: 0;
      left: 0;
      background: linear-gradient(180deg,
          rgba(var(--px_color_background), 0),
          rgba(var(--px_color_background), 1),
          rgba(var(--px_color_background), 1));
      background-attachment: fixed;
    }
  }

  @media (max-width: 1023px) {
    flex-basis: auto !important;
    flex: 0 0 auto !important;
    width: 100% !important;
  }
}

.px-stock-filter-group {
  // width: 95%;
  // max-height: 200px;
  // overflow-y: auto;
}

.px-daily-report {
  position: relative;
  right: initial;
  top: initial;
  font-size: 0.8rem;

  .px-stock-alert-badge .MuiBadge-badge {
    top: 7px;
    right: 2px;
  }
}

.px-daily-report,
.px-daily-report li {
  margin: 0;
  padding: 0;
  list-style: none;
}

.px-daily-report li {
  display: inline-block;
  padding: 0 4px;
  line-height: 45px;
  text-transform: uppercase;
  font-weight: bold;
}

.px-daily-report li:first-of-type {
  padding-left: 0;
}

.px-cart-icon-li {
  position: absolute;
  top: -70px;
  right: 0;
}

.px-close-filters {
  position: fixed;
  top: -40px;
  right: -120px;
  padding: 10px;

  .MuiChip-root {
    margin-bottom: 20px;
  }
}

.px-filters {
  display: none;

  .px-filters-backdrop {
    display: none;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 1299;
  }

  .px-filters-bar {
    position: fixed;
    top: 0;
    left: 0;
    padding: 0 8px 40px 16px;
    width: 80%;
    height: 100vh;
    z-index: 1300;
    background-color: RGB(var(--px_color_background));
    overflow-y: auto;

    h3 {
      font-size: 1.3rem;
      font-weight: bold;
    }

    .MuiFormControlLabel-root {
      margin-right: 0;
      word-break: break-word;
    }
  }
}

.px-filter-group {
  align-items: center;

  h3 {
    margin-bottom: 0;
  }
}

.px-grade-group {
  .px-grade-header {
    display: grid;
    grid-template-columns: fit-content(85%) max-content;
  }
}

.px-warehouse-label-group {
  align-items: center;
}

div[role='tooltip'].px-warehouse-popper {
  top: 10px !important;
  z-index: 1500;
  padding: 10px;
}

.px-grade-info.MuiButtonBase-root.MuiIconButton-root {
  padding: 0;
  margin-left: 10px;
  color: RGB(var(--px_color_text_secondary));

  &:hover {
    color: RGB(var(--px_color_text_secondary));
    background-color: RGBA(var(--px_color_primary), var(--px_alpha_light));
  }
}

.px-warehosue-group-info.MuiButtonBase-root.MuiIconButton-root {
  padding: 0 0 0 5px;

  &:hover {
    background-color: transparent;
    color: RGB(var(--px_color_primary));
  }
}

.px-warehouse-container {
  display: grid;
  grid-template-columns: 1fr auto;
}

.px-warehouse-name {
  word-break: break-word;
}

.px-cart-icon-li {
  .px-stocklist-cart-icon-button.MuiIconButton-root {
    padding: 0;

    &:hover {
      background: none;
    }
  }

  .px-stocklist-cart-icon.MuiSvgIcon-root {
    font-size: 42px;
    //color: RGB(var(--px_color_primary));
  }
}

.px-filters.show {
  display: initial;

  .px-close-filters {
    display: initial;
  }

  .px-filters-backdrop {
    display: initial;
  }
}

.alertQuantityInputField {
  width: 100px;
}

.export-menu-popper {
  .export-menu-list {
    padding: 8px 0;

    li {
      display: block;
      padding: 6px 16px !important;
      line-height: 1.5;
      font-weight: 400;
      text-transform: none;
    }
  }
}

@media screen and (min-width: 1024px) {
  .px-daily-report {
    position: absolute;
    right: 0;
    top: -50px;
    font-size: 1rem;
  }

  .px-cart-icon-li {
    position: relative;
    top: initial;
    right: initial;
  }

  .px-daily-report li {
    padding: 0 4px;
  }

  .px-filters {
    display: block !important;

    &.px-stock-list-sm-filter {
      flex: 0 0 auto;
      width: var(--px_stock_list_filter, 30%) !important;
    }

    .px-filters-backdrop {
      display: none;
      z-index: 201;
    }

    .px-filters-bar {
      position: relative;
      padding: 0 8px 40px 0;
      width: initial;
      height: auto;
      overflow-y: initial;
      white-space: pre-line;
      z-index: 202;

      .px-filters-items .filters-header-group {
        width: 100%;
      }
    }

    .px-close-filters {
      display: none;
    }
  }

  .px-filters.show {
    display: block;

    .px-filters-backdrop {
      display: none;
      z-index: 201;
    }

    .px-close-filters {
      display: none;
    }
  }
}

.px-filters-items {
  .special-price {
    .MuiSvgIcon-root {
      color: RGB(var(--px_color_badge_1));
      margin-left: 5px;
    }
  }
}

.px-special-price-snackbar {
  position: relative !important;
  transform: none;

  .MuiPaper-root.MuiAlert-root {
    background-color: RGB(var(--px_color_badge_1)) !important;
  }
}

.stocklist-snackbars {
  display: flex;
  flex-direction: column;
  position: fixed;
  top: -5px;
  z-index: 1000;
  left: 0;

  @media screen and (min-width: 600px) {
    gap: 10px;
    left: 50%;
    transform: translateX(-50%);
  }
}

.px-orders-offers-notification-snackbar {
  position: relative !important;
  transform: none;

  .MuiPaper-root.MuiAlert-root {
    background-color: RGB(var(--px_color_badge_4)) !important;
  }
}

.margin-top-10 {
  margin-top: 10rem;
}

.px-color-secondary,
.px-color-secondary:hover {
  color: RGB(var(--px_color_text_secondary)) !important;
}

.MuiTooltip-popper:has(.px-stock-lot-item-info) {
  z-index: 9999;
  .px-stock-lot-item-info {
    margin: 0;
    font-size: 14px;
  }
}

.px-stock-lot-item-info {
  > span {
    display: flex !important;
    align-items: center;
    padding: 8px 0;
    fill: RGB(var(--px_color_link));

    svg {
      margin-right: 8px;
    }
  }

  &.px-stock-lot-inline-btn > span{
    display: inline-block !important;
    position: relative;
    top: 0.35em;
  }

}
