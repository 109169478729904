.px-orders-management {
  padding-bottom: 50px;

  .flex-nowrap {
    flex-wrap: nowrap;
  }

  .px-consolidation-chip {
    margin-bottom: 0rem !important;
    display: inline-block;
    white-space: nowrap;
  }

  .px-consolidation-group-members {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .px-row-key-value-col > * {
    padding: 8px 0 8px 0;
  }

  .px-row-key {
    font-weight: 500;
    font-size: 12px;
    color: RGB(var(--px_color_text_secondary));
  }

  .px-grid-chips {
    font-size: 10px;
    word-wrap: unset;
  }

  .px-margin-right-1-manage {
    margin-right: 8px;
  }

  .px-display-flex-wrap {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
  }

  .px-order-detail-meta-data {
    display: flex;
    flex-direction: column;
    width: 100%;
  }

  .px-billing-shipping-container {
    width: 100%;
  }

  .px-order-detail-calc-total {
    width: 90%;
  }

  .px-order-edit-textfield {
    min-width: 80px;

    .MuiFormHelperText-root {
      line-height: 1.3em;
    }
  }

  .px-float-rtl {
    display: flex;
    flex-direction: row-reverse;
  }

  .px-order-action-button {
    min-width: 200px;
    margin: 4px 8px;
  }

  .px-bottom-subtotal-text {
    line-height: 24px;
    padding: 16px 0 16px 0;

    & > * {
      padding: 8px 0;
    }

    .px-bottom-subtotal-meta-data {
      font-size: 12px;
    }

    .px-bottom-subtotal-data {
      padding-left: 8px;
      font-size: 16px;
    }
  }

  .px-right-subtotal-text {
    line-height: 18px;
    padding: 2px 0 2px 0;

    & > * {
      padding: 4px;
    }

    .px-right-subtotal-meta-data {
      font-size: 14px;
    }

    .px-right-subtotal-data {
      padding-left: 8px;
      font-size: 16px;
    }
  }

  @media (min-width: 640px) {
    padding-bottom: 50px;

    .px-row-key {
      font-size: 16px;
    }

    .px-billing-shipping-container {
      width: 80%;
    }

    .px-margin-right-1-manage {
      margin-right: 16px;
    }

    .px-grid-chips {
      font-size: 14px;
    }

    .px-order-details-meta-data-container {
      display: flex;
      flex-direction: row;
    }

    .px-order-detail-meta-data {
      width: 75%;
    }

    .px-order-detail-calc-total {
      width: 30%;
    }

    .px-bottom-subtotal-text {
      line-height: 24px;
      padding: 16px 0 16px 0;

      & > * {
        padding: 8px 0;
      }

      .px-bottom-subtotal-data {
        padding-left: 8px;
        font-size: 16px;
      }
    }
  }
}

.px-insurance-amount .MuiOutlinedInput-notchedOutline legend {
  max-width: 150px;
}

.px-action-btn-dialog-unsaved-changes {
  border: 1px solid RGB(var(--px_color_background));
  padding: 2px 16px;
  display: flex;
  justify-content: center;
}

.px-grid-chips {
  padding: 4px 12px;
  border-radius: 16px;
  color: RGB(var(--px_color_text_on_primary));
  font-size: 14px;
}
.px-reservation-cancellation-dialog {
  h5 {
    font-weight: 600;
  }
  .MuiDialogActions-root {
    justify-content: space-between;
  }
  .px-terms-and-conditions {
    display: flex;
    justify-content: flex-end;
  }
}

.px-color-primary {
  color: RGB(var(--px_color_primary));
}

.px-background-primary {
  background-color: RGB(var(--px_color_primary));
}

.px-background-secondary {
  background-color: RGB(var(--px_color_secondary));
}

.px-background-error {
  background-color: RGB(var(--px_color_error));
}

.px-background-badge_1,
.px-badge-background-badge_1 .MuiBadge-badge {
  background-color: RGB(var(--px_color_badge_1));
}

.px-background-badge_1b,
.px-badge-background-badge_1b .MuiBadge-badge {
  background-color: RGB(var(--px_color_badge_1b));
}

.px-background-badge_2,
.px-badge-background-badge_2 .MuiBadge-badge {
  background-color: RGB(var(--px_color_badge_2));
}

.px-background-badge_2b,
.px-badge-background-badge_2b .MuiBadge-badge {
  background-color: RGB(var(--px_color_badge_2b));
}

.px-background-badge_3,
.px-badge-background-badge_3 .MuiBadge-badge {
  background-color: RGB(var(--px_color_badge_3));
}

.px-background-badge_3b,
.px-badge-background-badge_3b .MuiBadge-badge {
  background-color: RGB(var(--px_color_badge_3b));
}

.px-background-badge_4,
.px-badge-background-badge_4 .MuiBadge-badge {
  background-color: RGB(var(--px_color_badge_4));
}

.px-background-badge_5,
.px-badge-background-badge_5 .MuiBadge-badge {
  background-color: RGB(var(--px_color_badge_5));
}

.px-background-badge_6,
.px-badge-background-badge_6 .MuiBadge-badge {
  background-color: RGB(var(--px_color_badge_6));
}

.px-background-text_muted_on_background,
.px-badge-background-text_muted_on_background .MuiBadge-badge {
  background-color: RGB(var(--px_color_text_muted));
}

.px-background-text_secondary_on_background,
.px-badge-background-text_secondary_on_background .MuiBadge-badge {
  background-color: RGB(var(--px_color_text_on_secondary));
}

.px-border-bottom-1px {
  border-bottom: 1px solid #000000;
}

@keyframes flipX {
  0% {
    transform: scaleY(1);
  }

  50% {
    transform: scaleY(0);
  }

  100% {
    transform: scaleY(1);
  }
}

.px-order-bulksave_container .MuiCollapse-wrapperInner {
  height: 60px;
  position: fixed;
  bottom: 0;
  left: 0;
  z-index: 500;
  background-color: RGB(var(--px_color_primary));
  color: RGB(var(--px_color_text_on_primary));
  transition: all 0.3s ease-out;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  column-gap: 50px;
  padding-right: 200px;
  max-width: 1176px;
  left: 50%;
  transform: translateX(-49%);

  .MuiButtonBase-root.MuiButton-outlined.px-order-bulksave_cancel,
  .MuiButtonBase-root.MuiButton-outlined.px-order-bulksave_cancel:hover,
  .MuiButton-root.MuiButton-outlined,
  .MuiButton-root.MuiButton-outlined.px-order-bulksave_cancel:hover {
    border-color: RGB(var(--px_color_text_on_primary));

    .MuiButton-label {
      color: RGB(var(--px_color_text_on_primary));
    }

    &.Mui-disabled {
      border-color: RGB(var(--px_color_border)) !important;

      .MuiButton-label {
        color: RGB(var(--px_color_border)) !important;
      }
    }
  }

  .MuiButtonBase-root.MuiButton-containedPrimary,
  .MuiButtonBase-root.MuiButton-containedPrimary:hover,
  .MuiButton-root.MuiButton-containedPrimary,
  .MuiButton-root.MuiButton-containedPrimary:hover {
    background-color: RGB(var(--px_color_text_on_primary));

    .MuiButton-label {
      color: RGB(var(--px_color_primary));
    }

    &.Mui-disabled {
      background-color: RGB(var(--px_color_border)) !important;

      .MuiButton-label {
        color: RGB(var(--px_color_text_muted)) !important;
      }
    }
  }

  .px-order-bulksave_save,
  .px-order-bulksave_cancel {
    width: 92px;
  }

  .px-order-bulksave_change {
    margin: 0;
    &.px-animate-bulkchange {
      display: inline-block;
      animation: flipX 0.8s;
    }
  }
}

@media screen and (max-width: 1024px) {
  .px-order-bulksave_container .MuiCollapse-wrapperInner {
    padding-right: 50px;
    bottom: 55px;
  }
}

.px-grace-period-snackbar .MuiPaper-root.MuiAlert-root {
  background-color: RGB(var(--px_color_badge_4)) !important;
}

.margin-top-5 {
  margin-top: 5rem;
}
.po-snackBar-alert {
  .MuiSvgIcon-root {
    font-size: 1.8rem;
  }

  .MuiAlert-filledInfo {
    background-color: #f57d00 !important;
  }

  .MuiAlert-icon {
    padding-top: 14px;
  }

  &.px-inventory-snackbar .MuiPaper-root.MuiAlert-root {
    background-color: #f57d00;
  }

  .MuiAlert-message {
    display: flex;
    align-items: center;
    column-gap: 10px;
  }

  .MuiButtonBase-root .MuiButton-label,
  .MuiButton-root .MuiButton-label {
    color: white !important;
    .snack-button {
      margin-left: 20px;
    }
  }
}

.px-consolidation-tooltip {
  margin-top: 8px;

  .MuiTooltip-tooltip {
    margin: 0px;
  }
}
