.px-error-view {
    padding: 40px 0 0 120px;
    min-height: 92vh;

    h2 {
        font-size: 60px;
        font-weight: 300;
        font-style: normal;
        letter-spacing: -0.5px;
        text-align: left;
        line-height: 60px;
        padding: 0 0 40px 0;
    }

    h4 {
        font-size: 24px;
        font-weight: 400;
        font-style: normal;
        letter-spacing: 0px;
        text-align: left;
        line-height: 31.92px;
        padding: 0 0 20px 0;
    }

    h6 {
        font-size: 16px;
        font-weight: 400;
        font-style: normal;
        letter-spacing: 0.09px;
        text-align: left;
        line-height: 24px;
        padding: 0 0 10px 0;
    }

    .cell {
        font-size: 16px;
        font-weight: 400;
        font-style: normal;
        letter-spacing: 0.09px;
        text-align: left;
        line-height: 24px;
    }

    .px-action-btn {
        padding: 16px 0;

        button.MuiButtonBase-root {
            margin: 0 16px 0 0;
        }
    }

    .px-logout-btn {
        margin-top: 16px;
    }
}