.px-error-view {
    padding: 40px 0 0 120px;

    h2 {
        opacity: 1;
        color: rgba(0, 0, 0, 0.87);
        font-family: "Roboto-Light";
        font-size: 60px;
        font-weight: 300;
        font-style: normal;
        letter-spacing: -0.5px;
        text-align: left;
        line-height: 60px;
    }

    h4 {
        opacity: 1;
        color: rgba(0, 0, 0, 0.87);
        font-family: "Roboto-Regular";
        font-size: 24px;
        font-weight: 400;
        font-style: normal;
        letter-spacing: 0px;
        text-align: left;
        line-height: 31.92px;
    }

    h6 {
        opacity: 1;
        color: rgba(117, 117, 117, 1);
        font-family: "Roboto-Regular";
        font-size: 16px;
        font-weight: 400;
        font-style: normal;
        letter-spacing: 0.09px;
        text-align: left;
        line-height: 24px;
    }

    .cell {
        opacity: 1;
        font-family: "Roboto-Regular";
        font-size: 16px;
        font-weight: 400;
        font-style: normal;
        letter-spacing: 0.09px;
        text-align: left;
        line-height: 24px;
    }

    .px-text-description {
        color: rgba(117, 117, 117, 1);
    }

    .px-action-btn {
        padding: 16px 0;

        button.MuiButtonBase-root {
            margin: 0 16px 0 0;
            border-radius: 4px;
            border: 1px solid rgba(33, 150, 243, 1);
            color: 1px solid rgba(33, 150, 243, 1);
            opacity: 1;
        }
    }

    .px-logout-btn {
        margin-top: 16px;
        border-radius: 4px;
        opacity: 1;
        background-color: rgba(33, 150, 243, 1);
        color: rgb(255, 255, 255);
    }
}