.px-whatsapp-widget {
  position: fixed;
  z-index: 100000;

  svg {
    background-color: #00D621;
    color: #ffffff;
    height: 52px;
    width: 52px;
    padding: 4px;
    border-radius: 50%;
  }
}

.px-whatsapp-widget.bottom-left {
  left: 20px;
  bottom: 20px;
}

.px-whatsapp-widget.bottom-right {
  right: 20px;
  bottom: 20px;
}

.px-whatsapp-widget.top-left {
  left: 20px;
  top: 20px;
}

.px-whatsapp-widget.top-right {
  right: 20px;
  top: 20px;
}

@media screen and (max-width: 1024px) {
  .px-whatsapp-widget {
    transform: scale(0.6) !important;
  }

  .px-whatsapp-widget.bottom-left {
    left: 6px;
    bottom: 52px;
  }
  
  .px-whatsapp-widget.bottom-right {
    right: 6px;
    bottom: 52px;
  }

  .px-whatsapp-widget.top-left {
    left: 20px;
    top: 100px;
  }
  
  .px-whatsapp-widget.top-right {
    right: 20px;
    top: 100px;
  }
}
