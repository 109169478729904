@import 'utilities';

$spaceamounts: (
  2,
  4,
  6,
  8,
  10,
  12,
  14,
  16,
  18,
  20
); // Adjust this to include the pixel amounts you need.
$sides: (top, bottom, left, right); // Leave this variable alone

@include generate_padding_margins($spaceamounts, $sides);

.px-s-form-row {
  height: 60px;
  line-height: 60px;
  vertical-align: middle;
}

.disabled {
  pointer-events: none;
}

.px-pointer {
  cursor: pointer;

  * {
    cursor: pointer;
  }
}

.border-top {
  border-top: 1px solid RGB(var(--px_color_border));
}

.border-bottom {
  border-bottom: 1px solid RGB(var(--px_color_border)) !important;
}

.border-left {
  border-left: 1px solid RGB(var(--px_color_border));
}

.strong {
  font-weight: bold;
}

.line {
  border-top: 1px solid RGB(var(--px_color_border));
}

.inline {
  display: inline;
}

.small {
  font-size: 0.9rem;
}

.px-fontsize-12px {
  font-size: 12px;
}

.faded_1 {
  color: RGB(var(--px_color_text_primary));
}

.block {
  display: block !important;
}

.inline-block {
  display: inline-block;
}

.hide-for-sm-only {
  display: none !important;
}

.show-for-sm-only {
  display: inline-block !important;
}

.border-lg-top {
  border-top: initial;
}

.px-popup {
  max-width: 500px;
}

.vertical-top {
  vertical-align: top;
}

.vertical-middle {
  vertical-align: middle;
}

.px-align-middle {
  display: flex;
  align-items: center;
}

.text-faded {
  opacity: 0.5;
}

.text-muted {
  color: RGBA(var(--px_color_text_primary), var(--px_alpha_muted)) !important;
}

.link,
a {
  color: RGB(var(--px_color_link));
  display: inline-block;
}

.thin-line-height {
  line-height: 1rem;
}

.relative {
  position: relative;
}

.hover-bg:hover {
  background-color: RGBA(var(--px_color_primary), var(--px_alpha_lighter));
}

.hover-bg-light:hover {
  background-color: RGBA(var(--px_color_primary), var(--px_alpha_lighter));
  display: inline-block;
  height: auto;
}

.inline-block {
  display: inline-block;
}

.padding-right-10 {
  padding-right: 10px;
}

.padding-left-10 {
  padding-left: 10px !important;
}

.padding-left-20 {
  padding-left: 20px;
}

.padding-right-30 {
  padding-right: 30px !important;
}

.padding-top-4px {
  padding-top: 4px;
}

.padding-top-10 {
  padding-top: 16px;
}

.padding-bottom-10 {
  padding-bottom: 16px;
}

.no-margin {
  margin: 0 !important;
}

.px-box {
  border-radius: 5px;
  border: 1px solid RGB(var(--px_color_border));
  // -webkit-box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.6);
  // -moz-box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.6);
  // box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.6);
}

.px-hide-scrollbar {
  overflow: scroll;
  -ms-overflow-style: none;
  scrollbar-width: none;

  &::-webkit-scrollbar {
    display: none;
  }
}

.uppercase {
  text-transform: uppercase;
}

.lowercase {
  text-transform: lowercase;
}

.capitalize {
  text-transform: capitalize;
}

.text-right-sm {
  text-align: right;
}

.px-margin-right {
  margin-right: 5px !important;
}

.px-margin-right-5 {
  margin-right: 5px !important;
}

.px-margin-left-5 {
  margin-left: 5px !important;
}

.px-margin-right-15 {
  margin-right: 15px !important;
}

.px-margin-left-15 {
  margin-left: 15px !important;
}

.px-margin-bottom-15 {
  margin-bottom: 15px !important;
}

.px-margin-right-auto {
  margin-right: auto !important;
}

.px-bold-600 {
  font-weight: 600;
}

.px-bold-700 {
  font-weight: 700;
}

.badge {
  display: inline-block;
  min-width: 2em;
  padding: 0.3em;
  border-radius: 50%;
  font-size: 0.6rem;
  text-align: center;
  vertical-align: super;

  &.alert {
    background-color: #cc4b37;
    color: #fefefe;
  }
}

.go-to-top {
  top: -1px;
  position: sticky;
  background-color: RGB(var(--px_color_background));
  z-index: 10;
  transition: 0.2s ease-out;
}

.box-shadow-3 {
  //box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.6);
  box-shadow: 0 6px 4px -4px rgba(0, 0, 0, 0.7) !important;
}

.hide-for-sm-only-display-block {
  display: none !important;
}

.height-100 {
  height: 100%;
}

.px-fontsize-12px {
  font-size: 12px;
}

.padding-top-3px {
  padding-top: 3px;
}

.px-flex-column {
  display: flex;
  flex-direction: column;
}

.px-flex-row {
  display: flex;
  flex-direction: row;
}

.px-flex-center {
  display: flex;
  align-items: center;
  justify-content: center;
}

// >> mui theme styles

// change the color of snackbar

.MuiTabs-root {
  .MuiTab-root.MuiTab-textColorSecondary {
    color: RGBA(var(--px_color_text_secondary), var(--px_alpha_darker));

    &.Mui-selected {
      color: RGB(var(--px_color_text_primary));
    }
  }
}

.MuiChip-root {
  //padding: 8px;

  &.MuiChip-outlinedPrimary {
    color: RGB(var(--px_color_primary));
    border-color: RGB(var(--px_color_primary));

    .MuiSvgIcon-root {
      color: RGB(var(--px_color_primary));
    }
  }

  // .MuiChip-label {
  //   font-size: 14px;
  // }
}

.MuiSwitch-root {
  .MuiSwitch-thumb {
    color: RGB(var(--px_color_background));
  }

  .MuiSwitch-track {
    background-color: RGB(var(--px_color_info));
  }

  .MuiSwitch-colorPrimary.Mui-checked {
    .MuiSwitch-thumb {
      color: RGB(var(--px_color_primary));
    }

    & + .MuiSwitch-track {
      background-color: RGBA(var(--px_color_primary), 1);
    }
  }

  .MuiButtonBase-root.Mui-disabled {
    .MuiSwitch-thumb {
      color: unset;
    }
  }
}

.MuiFormLabel-root {
  color: RGB(var(--px_color_text_secondary)) !important;

  &.Mui-error {
    color: RGB(var(--px_color_error_input)) !important;
  }
}

.MuiInputBase-root {
  color: RGB(var(--px_color_text_primary)) !important;
}

.MuiInputAdornment-root {
  color: RGB(var(--px_color_text_primary));
}

.MuiButtonBase-root,
.MuiButton-root {
  .MuiButton-label {
    font-size: 14px;
    font-weight: 500;
  }

  &.MuiButton-textPrimary {
    color: RGB(var(--px_color_primary));
  }

  &.MuiButton-outlined,
  &.MuiButton-outlined:hover {
    border-color: RGB(var(--px_color_primary));

    .MuiButton-label {
      color: RGB(var(--px_color_primary));
    }

    &.Mui-disabled .MuiButton-label {
      color: RGB(var(--px_color_border));
    }
  }

  &.MuiButton-containedPrimary,
  &.MuiButton-containedPrimary:hover {
    background-color: RGB(var(--px_color_primary));
  }

  &.MuiPickersDay-daySelected {
    color: RGB(var(--px_color_text_on_primary)) !important;
    background-color: RGB(var(--px_color_primary)) !important;
  }

  &.MuiIconButton-colorPrimary.MuiIconButton-root:not(.MuiPickersDay-dayDisabled, .MuiPickersDay-daySelected, .Mui-disabled) {
    color: RGB(var(--px_color_primary)) !important;
  }

  &.MuiPickersDay-dayDisabled {
    color: RGB(var(--px_color_text_muted)) !important;
  }

  &.MuiIconButton-root {
    color: RGB(var(--px_color_primary));
  }
}

.MuiToolbar-root.MuiPickersToolbar-toolbar {
  color: RGB(var(--px_color_text_on_primary));
  background-color: RGB(var(--px_color_primary));
}

.MuiLinearProgress-root {
  &.MuiLinearProgress-colorPrimary {
    .MuiLinearProgress-dashedColorPrimary {
      background-color: RGBA(var(--px_color_primary), var(--px_alpha_lighter));
    }

    .MuiLinearProgress-bar.MuiLinearProgress-colorPrimary.MuiLinearProgress-bar2Buffer {
      background-color: RGBA(var(--px_color_primary), var(--px_alpha_muted));
    }

    .MuiLinearProgress-barColorPrimary {
      background-color: RGB(var(--px_color_primary));
    }
  }
}

.MuiTextField-root {
  .MuiOutlinedInput-root {
    .MuiOutlinedInput-notchedOutline {
      border-color: RGBA(var(--px_color_text_secondary), var(--px_alpha_darker));
    }

    &.Mui-error .MuiOutlinedInput-notchedOutline {
      border-color: RGB(var(--px_color_error_input));
    }

    &.Mui-focused,
    &.Mui-focused:focus,
    &.Mui-focused:focus-visible {
      border-color: RGB(var(--px_color_primary));
      outline: RGB(var(--px_color_primary));
    }
  }

  .MuiFormHelperText-root.Mui-error {
    color: RGB(var(--px_color_error_input));
  }
}

.MuiBadge-badge.MuiBadge-colorPrimary {
  background-color: RGB(var(--px_color_primary));
}

.MuiFormLabel-root {
  color: RGB(var(--px_color_text_secondary)) !important;

  &.Mui-error {
    color: RGB(var(--px_color_error_input)) !important;
  }
}

.MuiPaper-root.MuiMenu-paper {
  background-color: RGB(var(--px_color_background));
}

.MuiPaper-root.MuiDialog-paper {
  background-color: RGB(var(--px_color_background));
  color: RGB(var(--px_color_text_primary));
}

.MuiPopover-root,
div.MuiBackdrop-root {
  background-color: RGBA(var(--px_color_border), var(--px_alpha_medium));
}

.MuiDropzoneArea-root {
  &,
  .MuiDropzoneArea-icon {
    color: RGB(var(--px_color_primary));
    border-color: RGB(var(--px_color_primary));
  }

  border: 1px dashed !important;

  &[tabindex='0'] {
    background-color: RGB(var(--px_color_background));
  }

  &.Mui-error {
    &,
    .MuiDropzoneArea-icon {
      color: RGB(var(--px_color_error));
      border-color: RGB(var(--px_color_error));
    }
  }

  .MuiDropzonePreviewList-image,
  .MuiDropzonePreviewList-removeButton {
    color: RGB(var(--px_color_primary));
    background-color: RGB(var(--px_color_background));
  }

  .MuiDropzonePreviewList-removeButton:hover {
    color: RGB(var(--px_color_text_on_primary));
    background-color: RGB(var(--px_color_primary));
  }
}

.MuiInputBase-root {
  color: RGB(var(--px_color_text_primary)) !important;
}

.MuiInputAdornment-root {
  color: RGB(var(--px_color_text_primary));
}

.MuiChip-root.px-filter-on {
  background-color: RGB(var(--px_color_primary));
  color: RGB(var(--px_color_text_on_primary));

  // &:hover {
  //   background-color: RGB(var(--px_color_primary)) !important;
  // }

  .MuiSvgIcon-root {
    color: RGB(var(--px_color_text_on_primary));
  }
}

.MuiTabs-root .MuiTabs-indicator {
  background-color: RGB(var(--px_color_primary));
}

.MuiSnackbar-root {
  z-index: 10000 !important;
  .MuiAlert-root {
    color: RGB(var(--px_color_text_on_snackbar));

    &.MuiAlert-filledInfo {
      background-color: RGB(var(--px_color_info));
    }

    &.MuiAlert-filledError {
      background-color: RGB(var(--px_color_error));
    }

    &.MuiAlert-filledSuccess {
      background-color: RGB(var(--px_color_success));
    }

    &.MuiAlert-filledWarning {
      background-color: RGB(var(--px_color_warning));
    }

    .MuiAlert-icon,
    .MuiAlert-message,
    .MuiAlert-action .MuiIconButton-label {
      color: RGB(var(--px_color_text_on_snackbar));
    }
  }
}

.MuiFab-root.MuiFab-primary {
  color: #ffffff;
  background-color: RGB(var(--px_color_primary));

  &:hover {
    background-color: RGB(var(--px_color_primary));
  }
}

.MuiRadio-root {
  &.MuiRadio-colorPrimary.Mui-checked {
    color: RGB(var(--px_color_primary));
  }
}

.MuiCheckbox-root {
  &.MuiCheckbox-colorPrimary.Mui-checked {
    color: RGB(var(--px_color_primary));
  }
}

.MuiSvgIcon-root {
  &.MuiSvgIcon-colorPrimary {
    color: RGB(var(--px_color_primary));
  }
}

// TODO: check this class
// .px-no-bg,
// .px-no-bg:hover,
// .MuiButton-root.px-no-bg,
// .MuiButton-root.px-no-bg:hover,
// .MuiButton-root.px-no-bg:hover span {
//   background-color: rgba(0, 0, 0, 0) !important;
// }

// TODO: add to existing order
.px-flex-start {
  align-items: flex-start !important;
}

.MuiButton-root.px-link-btn,
.MuiButton-root.px-link-btn span {
  color: RGB(var(--px_color_primary)) !important;
  background-color: rgba(0, 0, 0, 0) !important;
}

.MuiButton-root.px-link-btn:hover,
.MuiButton-root.px-link-btn:hover {
  color: RGB(var(--px_color_primary)) !important;
  background-color: RGBA(var(--px_color_primary), var(--px_alpha_lighter)) !important;
}

.px-no-bg,
.px-no-bg:hover,
.MuiButton-root.px-no-bg,
.MuiButton-root.px-no-bg:hover,
.MuiButton-root.px-no-bg:hover span {
  background-color: rgba(0, 0, 0, 0) !important;
}

.MuiButton-root.px-link-btn,
.MuiButton-root.px-link-btn span {
  color: RGB(var(--px_color_primary)) !important;
  background-color: rgba(0, 0, 0, 0) !important;
}

.MuiButton-root.px-link-btn:hover,
.MuiButton-root.px-link-btn:hover {
  color: RGB(var(--px_color_primary)) !important;
  background-color: rgba(var(--px_color_primary), var(--px_alpha_lighter)) !important;
}

.px-text-description {
  // font-size: 12px;
  color: RGB(var(--px_color_text_secondary));
}

.px-color-text-on-secondary {
  color: RGB(var(--px_color_text_on_secondary));
}

#px-poe-document-list {
  max-height: 500px;
}

@media screen and (max-height: 500px) {
  #px-poe-document-list {
    max-height: 100vh;
  }
}

[data-color='px_color_badge_4'] .MuiBadge-badge {
  background-color: RGB(var(--px_color_badge_4));
  color: RGB(var(--px_color_text_on_badge));
}

[data-color='px_color_badge_2'] .MuiBadge-badge {
  background-color: RGB(var(--px_color_badge_2));
  color: RGB(var(--px_color_text_on_badge));
}

.px-grid-loader {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.px-circular-loader {
  border: 8px solid RGBA(var(--px_color_primary), var(--px_alpha_lighter));
  /* Light */
  border-top: 8px solid RGB(var(--px_color_primary));
  /* Blue */
  border-radius: 50%;
  width: 48px;
  height: 48px;
  -webkit-animation: spin 2s linear infinite;
  /* Safari */
  animation: spin 2s linear infinite;
  color: RGB(var(--px_color_primary));
}

button.px-color-text {
  color: RGB(var(--px_color_text_primary));
}

/* Safari */
@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

div[role='tooltip'] {
  background-color: RGB(var(--px_color_background));
}

div[role='tooltip'].px-search-tooltip-popper {
  width: 386px;
  border-radius: 7px;
  padding: 15px 0 15px 15px;
  border-radius: 5px;

  h2 {
    font-weight: bold;
    margin: 20px 0 0 0;
  }

  & * {
    white-space: nowrap;
    background-color: #fff;
    color: var(--px_color_text_primary);
    font-size: 1rem;

    .text-muted {
      vertical-align: middle;
      font-size: 1.3rem;

      svg {
        font-size: 26px;
        margin-right: 10px;
        vertical-align: middle;
      }
    }
  }
}

div[role='tooltip'].px-app-version-tooltip {
  box-shadow: none;
  background-color: unset;
  .px-box {
    background-color: unset;
  }
}

.px-accounts-menu {
  z-index: 11;
}

.px-paypal-icon {
  height: 42px;
}

.px-paypal-text {
  padding-top: 12px;
  max-width: 240px;
  margin-bottom: 0;
}

#px-continue-to-paypal {
  margin-bottom: 20px;
  text-transform: none;
  padding: 0;
}

.paypal-failed-payment {
  background-color: RGB(var(--px_color_secondary)) !important;
  padding: 16px;
}

.px-paypal-failed-payment-box {
  width: 240px !important;
}

div[role='tooltip'],
.px-box {
  border-radius: 5px;
  -webkit-box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.6);
  -moz-box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.6);
  box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.6);
}

.px-text-bold {
  font-weight: 600;
}

.px-text-normal {
  font-weight: 400;
}

.px-text-align-justify {
  text-align: justify;
}

.show-for-large-only {
  display: none !important;
}

.float-right {
  float: right;
}

.px-display-none {
  display: none;
}

.gap-10 {
  gap: 10px;
}

.gap-15 {
  gap: 15px;
}

.gap-25 {
  gap: 25px;
}

@media screen and (min-width: 640px) {
  .hide-for-medium-large-only {
    display: none !important;
  }

  .hide-for-sm-only-display-block {
    display: block !important;
  }
}

@media screen and (min-width: 1024px) {
  .px-box {
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
  }

  .hide-for-sm-only {
    display: inline-block !important;
  }

  .show-for-sm-only {
    display: none !important;
  }

  .show-for-sm-medium-only {
    display: none !important;
  }

  .show-for-large-only {
    display: block !important;
  }

  .border-lg-top {
    border-top: 1px solid RGB(var(--px_color_border));
  }

  .text-right-sm {
    text-align: initial;
  }

  .px-margin-right {
    margin-right: 30px !important;
  }

  .px-main-padding {
    padding-left: 52px;
  }
}

.px-home-padding {
  padding-top: 52px;
}

.px-hide-640px {
  display: none;
}

.px-poe-document {
  display: flex;
  flex-direction: column;
  margin-bottom: 16px;
  p {
    margin-bottom: 0;
    font-size: 16px;
  }
  .MuiDropzoneArea-root {
    min-height: 120px;
  }
}

.banner-preview-dialog .MuiDialog-paper .MuiDialogContent-root {
  min-height: 360px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
}

.banner-preview-dialog iframe {
  border: 0px;
  width: 500px;
  height: 500px;
}

.px-footer-link {
  padding-bottom: 8px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  a {
    padding: 8px 16px 8px 16px;
  }
}

.px-color-text-on-snackbar {
  color: RGB(var(--px_color_text_on_snackbar)) !important;
}

.px-underline {
  text-decoration: underline;
}

.px-full-screen-loader {
  height: 85vh;
  padding: 24px;
}

@media (max-width: 1024px) {
  .px-footer-link {
    padding-bottom: 52px;
  }
}

@media (min-width: 640px) {
  .px-hide-640px {
    display: block;
  }

  .px-show-640px {
    display: none;
  }
}

@media (max-width: 400px) {
  .margin-formControl.MuiFormControlLabel-root {
    .px-payment-method-instruction-container {
      margin-left: -32px;
      margin-right: -16px;

      * {
        font-size: 96%;
      }
    }
  }

  .font-small-h2 {
    font-size: 1.5rem;
  }
}

.error-helper-text {
  color: red;
}

.px-one-line-data {
  display: flex;
  align-items: center;
  overflow-wrap: anywhere;
}

.margin-left-auto {
  margin-left: auto !important;
}

.description-link {
  color: RGB(var(--px_color_link));
  cursor: pointer;
  &:hover {
    color: RGB(var(--px_color_link_holver));
  }
}

.px-cursor-default {
  cursor: default !important;
}

.mouse-pointer {
  cursor: pointer;
}

.px-color-link {
  color: RGB(var(--px_color_link)) !important;
}

.margin-bottom-20 {
  margin-bottom: 20px !important;
}

.text-align-right {
  text-align: right;
}

.text-align-center {
  text-align: center;
}
