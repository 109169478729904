.px-item {
  border: 0;
  margin-top: 20px;
  position: relative;

  h4 {
    font-size: 1.15rem;
    font-weight: 500;
    line-height: 44px;
    margin: 0;
    padding: 0 20px;
  }

  .px-item-description {
    position: relative;
  }

  // .px-item-code {
  //   position: absolute;
  //   right: 20px;
  //   z-index: 2;
  // }
  .px-item-title,
  .px-item-code {
    display: inline-block;
    margin: 12px 0;
  }

  .px-item-title {
    font-weight: normal;
    line-height: 25px;
  }

  .px-item-code {
    position: relative;
    right: 20px;
    display: flex;
    max-width: 50%;

    h4 {
      padding: 0;
      font-size: 20px;
      font-weight: bold;
      line-height: 25px;
      text-align: right;
    }
  }

  .px-item-warehouse {
    z-index: 2;
    display: flex;
    justify-content: flex-end;
    padding-left: 5px !important;

    h4 {
      padding: 0;
      text-transform: none;
    }

    button {
      padding: 0 0 0 5px;
    }
  }

  .px-item-warehouse-info.MuiButtonBase-root.MuiIconButton-root {
    position: absolute;
    right: 20px;
    bottom: 20px;
    padding: 0;
    z-index: 2;
    width: max-content;

    &:hover {
      color: RGB(var(--px_color_primary));
      background-color: RGB(var(--px_color_on_primary));
    }
  }

  &.expanded-group .px-item-warehouse-info.MuiButtonBase-root.MuiIconButton-root {
    bottom: 10px;
  }

  .px-grade-info.MuiButtonBase-root.MuiIconButton-root {
    padding: 0;
    margin-left: 10px;
    &:hover {
      color: RGB(var(--px_color_primary));
      background-color: RGB(var(--px_color_on_primary));
    }
  }

  .px-item-num,
  .px-item-qty,
  .px-item-price,
  .px-item-info,
  .px-item-cart {
    display: inline-block;
    line-height: 40px;
  }

  // .px-item-num {
  //   text-align: right;
  // }

  .px-item-info {
    // font-size: 0.8rem;
    h5 {
      font-size: 0.8rem;
      display: inline;
      margin-right: 5px;
    }
  }

  .px-item-cart a {
    display: inline-block;
    line-height: 44px;
  }

  .px-item-more {
    display: inline-block;
    border-radius: 0;
    bottom: 0;
    text-align: center;
    left: 0;
    font-weight: bold;
    font-size: 1.5rem;
    font-family: verdana, tahoma, helvetica;
    z-index: 1;
    padding: 6px 20px;

    button:hover {
      background-color: transparent;
      color: RGB(var(--px_color_primary));
    }

    span.MuiButton-label {
      display: flex;

      .px-open-stock-list-group-container {
        display: flex;
        justify-content: flex-end;

        .px-open-stock-list-group {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          min-width: 160px;
        }
      }

      .px-item-warehouse h4 {
        max-width: 100%;
        overflow-wrap: break-word;
      }
    }

    svg {
      display: inline-block;
    }
  }

  .px-items-list {
    padding: 0 20px;
    // div:not(:last-of-type) {
    //   margin-right: 35px;
    // }
  }

  .px-items-list-header {
    padding-right: 20px;
    // font-size: 12px;
    // &>*{
    //   line-height: 20px;
    // }
  }

  .px-items-list-content {
    padding-right: 20px !important;
    align-items: center;
    p[data-id="item"] {
      margin-bottom: 0;
    }
  }

  .px-item-cart-btns {
    padding-left: 20px;
    text-align: left;

    button {
      vertical-align: middle;
    }

    button.link {
      position: absolute;
      right: 0;
      top: 0;
    }
  }

  .px-item-item {
    padding: 5px 0;
    border-bottom: 1px solid #ddd;

    div:first-line {
      line-height: 45px !important;
    }
  }

  .px-item-alert {
    margin: 13px;
    // height: 45px;
    // line-height: 45px;
  }

  .px-item-cart-btns a:hover {
    background-color: #eee;
  }
}

.px-alert-item {
  display: inline-block;
  margin: 0 40px;
}
.flex-end {
  justify-content: end;
}

.px-stock-price {
  display: flex;
  align-items: center;

  .px-crossed-list-price {
    display: flex;
    align-items: center;
    flex-direction: row;
    .MuiSvgIcon-root {
      color: RGB(var(--px_color_badge_1));
      margin-right: 5px;
    }
    s {
      margin-right: 5px;
    }
  }
}

.expanded-group {
  .px-stock-price {
    flex-direction: row;
  }
}

@media screen and (max-width: 640px) {
  .px-item-description {
    padding-top: 10px;

    h4 {
      line-height: 25px;
    }
  }
}

@media screen and (min-width: 640px) {
  .px-item {
    .px-item-cart-btns {
      text-align: right;
    }
  }
}

.MuiTooltip-popper .MuiTooltip-tooltip.px-stocklist-group-warehouse-tooltip {
  margin: 0;
  font-size: 14px;
}

@media screen and (min-width: 1024px) {
  .px-item {
    h4 {
      font-size: 1.3rem;
    }

    .px-item-info {
      //font-size: 0.875rem;
      h5 {
        font-size: 0.875rem;
      }
    }

    // .px-items-list div {
    //   margin-right: 50px;
    // }

    .px-item-item {
      border-bottom: none;

      a,
      div {
        line-height: 1rem !important;
      }

      div:first-line {
        line-height: 45px !important;
      }

      // .px-item-cart-btns {
      //   text-align: right;
      //   button {
      //     vertical-align: middle;
      //   }
      // }
      .px-item-cart-btns a,
      .px-item-cart-btns div {
        line-height: 45px !important;
      }
    }

    // .px-item-num {
    //   text-align: left;
    // }
  }
}

@media (max-width: 760px) {
  .px-item
    .px-item-more
    span.MuiButton-label
    .px-open-stock-list-group-container
    .px-open-stock-list-group {
    min-width: unset;
  }
}

@media (max-width: 500px) {
  .expanded-group {
    .px-stock-price {
      flex-direction: column;
    }
  }
}

.px-autocheckout-prompt-actions {
  align-items: flex-start;
  gap: 20px;

  @media (max-width: 500px) {
    justify-content: center;
  }
}
