.px-stocklist {

  position: relative;

  .px-items-count>span {
    padding-right: 30px;
  }

  .px-stock-list-items {
    margin-top: 20px;
  }

}