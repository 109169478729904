.px-v-homepage {
  //padding: 20px;
  ul,
  li {
    list-style: none;
  }
  li {
    display: block;
    width: 100%;
  }
  li a {
    color: RGB(var(--px_color_text_primary));
    line-height: 44px;
    display: block;
    padding: 0 20px;
  }
  min-height: calc(100vh - 45px);
}
