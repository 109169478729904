* {
  box-sizing: border-box;
}

html {
  min-height: 100%;
  position: relative;
  font-size: 14px;
}

body {
  font-family: helvetica, arial, sans-serif;
  background-color: RGBA(var(--px_color_background), var(--px_alpha_muted)) !important;
  color: RGB(var(--px_color_text_primary)) !important;
  margin: 0 !important;
}

a,
a svg {
  color: RGB(var(--px_color_link));
  text-decoration: none;
}

a:active {
  color: RGB(var(--px_color_link_active));
}

a:visited {
  color: RGB(var(--px_color_link_visited));
}

a:hover,
a:hover svg {
  color: RGB(var(--px_color_link_hover));
}

main {
  padding: 20px;
}

small {
  font-size: 0.84rem;
}

.block {
  display: block;
}

.hide-scrollbar {
  scrollbar-width: none; /* Firefox */
  -ms-overflow-style: none; /* IE and Edge */

  &::-webkit-scrollbar {
    display: none; /* Chrome, Safari */
  }
}
