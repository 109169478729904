.px-c-logo {
    float: left;
    img {
      max-width: var(--px_topLogoWidth);
      max-height: var(--px_topLogoHeight);
      vertical-align: middle;
      display: inline-block;
    }
    .px-logoReferer {
        display: inline-block;
        height: 45px;
        vertical-align: middle;
    }
}
