#px-deactivated-tenant-content {
  text-align: center;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-style: normal;
  font-weight: 400;
  color: inherit;
  text-rendering: optimizeLegibility;
  line-height: 1.4;
  margin-top: 0;
  margin-bottom: .5rem;
  font-size: 32px;
  font-family: 'Arial', 'helvetica' !important;
}
